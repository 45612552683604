
import objectFitImages from 'object-fit-images';
import { H }  from './scripts/highway';
import { initPageload } from './scripts/pageload';
import { initRice } from './scripts/rice';

document.addEventListener("DOMContentLoaded", function() {
    objectFitImages('img.img-cover');
    initPageload();
    initRice(H);
});
