import Highway from '@dogstudio/highway';
import  Tweenlite  from 'gsap';

function changeBg(color){
    document.body.classList.remove('bg--primary','bg--secondary');
    document.body.classList.add(color);
}
export default class Fade extends Highway.Transition{
    in ({ from, to, done }) {

        document.body.classList.remove('template--about','template--impressions', 'template--contact', 'template--home', 'template--default');
        document.body.classList.add('template--'+to.getAttribute('data-router-view'))

        to.getAttribute('data-router-view') == 'impressions'
            ? changeBg('bg--primary')
            : changeBg('bg--secondary')

        Tweenlite.from( to, 0.6, { 
            ease:Power3.easeInOut,
            y: "30px", 
            opacity: 0, 
            onComplete: () => { done(); }
        });
    }
    out ({ from, done }) {
        Tweenlite.to( from, 0.6, { 
            ease:Power3.easeInOut,
            y: "-30px", 
            opacity: 0, 
            onComplete: () => {
                from.remove()
                done();
            }
        });
    }
}