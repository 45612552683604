// import  Tweenlite  from 'gsap';
import  { TimelineLite }  from 'gsap';

export function initPageload() {
    
    const logo = document.querySelectorAll('.header__logo .group');
    const bottomNavLinks = document.querySelectorAll('.bottom-menu__link');
    const menu_button = document.querySelector('#main-menu__btn div');
    const order_button = document.querySelector('.order-btn div');
    const main = document.querySelector('.site-main article');


    const logo_load = new TimelineLite();
    logo_load.staggerFromTo(logo, 0.8, 
    { yPercent: 130 }, 
    { yPercent: 0, ease:Power3.easeInOut, delay:0.6 }, 0.03)

    const topMenu_load = new TimelineLite(); 
    topMenu_load.fromTo([ menu_button, order_button ], 0.8, 
    { yPercent: 110 }, 
    { yPercent: 0, ease:Power3.easeInOut, delay:0.8 })

    const main_load = new TimelineLite(); 
    main_load.fromTo( main, 1, 
    { autoAlpha: 0, y: 70 }, 
    { autoAlpha: 1, y: 0, ease:Power2.easeInOut, delay:1.2 })
        .staggerFromTo(bottomNavLinks, 0.8, 
        { yPercent: 130 }, 
        { yPercent: 0, ease:Power3.easeInOut }, 0.15)
}