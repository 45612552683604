import { debounce } from 'lodash';
import { Swiper, Navigation, Lazy, Keyboard } from 'swiper/dist/js/swiper.esm.js';
Swiper.use([Navigation, Lazy, Keyboard]);

// Text slider (About page)
export function initTextSlider() {
    if ( document.querySelector('.text-slider')){
        let numbers = document.querySelectorAll(".circle .nr");
        for (let i=0; i < numbers.length; i++) {
            const nr = numbers[i];
            nr.style.transform = 'rotate(' + (360 / numbers.length) * i + 'deg)'
        }
        const circle = document.querySelector(".circle");
        let textSlider = new Swiper('.text-slider', {
            // preventInteractionOnTransition: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            speed: 500,
            navigation: {
                nextEl: '.to-next',
                prevEl: '.to-prev',
            },
            on: {
                slideChange: () => {
                    circle.style.transform = 'translateX(-50%) rotate(' + -((360 / numbers.length) * textSlider.activeIndex) + 'deg)'
                },
            },
        });
    }
}


// Image slider (Impressions page)
export function initImageSlider() {
    let wW = window.innerWidth || document.documentElement.clientWidth;
    let imageSlider_desk = undefined;
    let imageSlider_mob = undefined;

    if( wW >= 768 && document.querySelector('.image-slider--desktop')){
        init_imageSlider_desk();
    }
    if( wW <= 768 && document.querySelector('.image-slider--mobile')){
        init_imageSlider_mob();
    }
 
    window.addEventListener('resize', debounce(function(){
        wW = window.innerWidth || document.documentElement.clientWidth;
        if( wW <= 768 ){
                if( imageSlider_desk != undefined ){
                imageSlider_desk.destroy();
                imageSlider_desk = undefined;
            }
            init_imageSlider_mob();
        }
        if( wW >= 768 ){
            if( imageSlider_mob != undefined ){
                imageSlider_mob.destroy();
                imageSlider_mob = undefined;
            }
            init_imageSlider_desk();
        }
    }, 500));

    function init_imageSlider_desk(){
        imageSlider_mob = undefined;
        imageSlider_desk = new Swiper('.image-slider--desktop', {
            keyboard: {  enabled: true, onlyInViewport: false, },
            speed: 500,
            preloadImages: false,
            navigation: {
                nextEl: '.slider-arrow--desktop.to-next',
                prevEl: '.slider-arrow--desktop.to-prev',
            },
            lazy: {
                loadPrevNextAmount: 2,
                loadOnTransitionStart: true,
            },
            on: {
                init: () => {
                    document.querySelector('.image-slider--desktop').classList.add('loaded');
                },
            }
        });
    }
    function init_imageSlider_mob(){
        imageSlider_mob = new Swiper('.image-slider--mobile', {
            keyboard: {  enabled: true, onlyInViewport: false, },
            speed: 500,
            preloadImages: false,
            navigation: {
                nextEl: '.slider-arrow--mobile.to-next',
                prevEl: '.slider-arrow--mobile.to-prev',
            },
            lazy: {
                loadPrevNextAmount: 2,
                loadOnTransitionStart: true,
            },
            on: {
                init: () => {
                    document.querySelector('.image-slider--mobile').classList.add('loaded');
                },
            }
        });
    }
}