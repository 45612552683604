import Highway from '@dogstudio/highway';
import Fade from './transitions/fade';
// import MenuClose from './transitions/menuClose';

import { initImageSlider, initTextSlider } from './sliders';

class Impressions extends Highway.Renderer {
    onEnter() { 
        initImageSlider() 
    }
}

class About extends Highway.Renderer {
    onEnter() { 
        initTextSlider() 
    }
}

export const H = new Highway.Core({
    renderers: {
        about: About,
        impressions: Impressions,
    },
    transitions: {
        default: Fade,
        // contextual: {
        //     menuClose: MenuClose,
        // }
    },
});

const allLinks = document.getElementsByTagName( 'a' );
H.on('NAVIGATE_OUT', ({ location }) => {
    for (var i = 0, len = allLinks.length; i < len; i++)  {
        allLinks[i].href === location.href 
            ? allLinks[i].classList.add('is-active') 
            : allLinks[i].classList.remove('is-active');
    }
});
