import * as Matter from 'matter-js';
export function initRice(highway) {

    let wW = window.innerWidth || document.documentElement.clientWidth;
    let wH = window.innerHeight || document.documentElement.clientHeight;

    let riceAmount = wW < 600 ? 60 : 100;

    const riceCanvas = document.getElementById('rice-canvas');
    const engine = Matter.Engine.create();
    const World = engine.world;
    const Bodies = Matter.Bodies;

    let render = Matter.Render.create({
        canvas: riceCanvas,
        engine: engine,
        options: {
            width: wW,
            height: wH,
            background: 'transparent',
            wireframes: false,
        },
    });
    const 
        thickness = 200,
        ceiling = Bodies.rectangle( wW, -thickness, wW*2, thickness, { isStatic: true, }),
        floor = Bodies.rectangle( wW, wH+(thickness/2)+100, wW*2, thickness, { isStatic: true, }),
        leftWall = Bodies.rectangle( -((thickness/2)+30), (wH*2)/2, thickness, wH * 3, { isStatic: true, }),
        rightWall = Bodies.rectangle( wW+((thickness/2)+30), (wH*2)/2, thickness, wH * 3, {  isStatic: true, });

    Matter.World.add(World, [ ceiling, floor, leftWall, rightWall ]);

    let rice = [];
    function createRice(){
        for (let i = 0, len = riceAmount; i < len; i++)  {
            let grainNr = Math.floor(Math.random() * 6) + 1;
            let grain = Bodies.rectangle( Math.random() * wW, wH+30, 24, 10, {
                angle: Math.floor(Math.random() * 10),
                restitution: Math.floor(Math.random() * 10) / 20,
                friction: 0.3,
                frictionAir:  Math.floor(Math.random() * 10) / 1000,
                render: {
                    fillStyle: 'white',
                    sprite: { texture: 'assets/images/rice-sprites/grain-'+grainNr+'.png' }
                }
            });
            rice.push(grain)
        }
    }
    createRice();
  
    World.gravity.y = 0.4;
    Matter.Engine.run(engine);
    Matter.Render.run(render);
    
    // Without this timeout there is a little bit of render overload at the start of the pageload
    Matter.World.add(World, rice);
    setTimeout( randomPulse, 360 );
    
    const FORCE_STRENGTH = 0.00015;
    const RANDOM_FORCE_STRENGTH = 0.0001;

    const bottomNav = document.querySelectorAll('.bottom-menu__link'); 
    const mainNav = document.querySelectorAll('.main-menu__link'); 

    let maxPulses = 6;
    let pulseCount = 0;


    for (let i = 0, len = bottomNav.length; i < len; i++)  {
        bottomNav[i].addEventListener('click', (e) => bottomNavClick(e) );
    }
    for (let i = 0, len = mainNav.length; i < len; i++)  {
        mainNav[i].addEventListener('click', () => mainNavClick() );
    }


    function resetRice(){
        pulseCount = 0;
        Matter.World.remove(World, rice);
        rice = [];
        createRice();
        Matter.World.add(World, rice);
        randomPulse();
    }
    function randomPulse () {
        if(!document.hidden){
            let pulsePoint = randomPoint(4);
            pulseCount++;
            for (let i = 0, len = rice.length; i < len; i++)  {
                if( rice[i].position.y > ( wH + 10 ) ){
                    let rX = (rice[i].position.x).toFixed();
                    let disX =  Math.abs( rX - pulsePoint ).toFixed();
                    let force = ( (150 - Math.abs((disX / wW) * 150)) * RANDOM_FORCE_STRENGTH ).toFixed(3);
                    Matter.Body.applyForce( rice[i], {x: rice[i].position.x, y: rice[i].position.y}, 
                    {
                        x: 0, 
                        y: -force
                    });
                }
            }
        }
        if ( pulseCount < maxPulses ){
            setTimeout( randomPulse, 4200 )
        }
        if ( pulseCount >= maxPulses ){
            setTimeout( resetRice, 5500 )
        }
    }

    function bottomNavClick (e) {
        const mX = e.clientX
        for (let i = 0, len = rice.length; i < len; i++)  {
            if( rice[i].position.y > ( wH - 120 ) ){
                let rX = rice[i].position.x;
                let rY = rice[i].position.y;
                let disX =  Math.abs(rX - mX);
                let force = ( (100 - Math.abs((disX / wW) * 100)) * FORCE_STRENGTH ) * -1;
                Matter.Body.applyForce( rice[i], {x: rX, y: rY}, {x: 0, y: force});
            }
        }
    }
    function mainNavClick () {
        for (let i = 0, len = rice.length; i < len; i++)  {
            let rX = rice[i].position.x;
            let disX =  Math.abs( rX - (wW / 2) );
            let force = ( (100 - Math.abs((disX / wW) * 100)) * FORCE_STRENGTH ) * -1;
            Matter.Body.applyForce( rice[i], {x: rice[i].position.x, y: rice[i].position.y}, {x: 0, y: force});
        }
    }

    function randomPoint(division) {
        let points = [];
        for(let i = 0, len = division; i < len; i++){  
            points.push( (wW / (division - 1) ) * i ) 
        }
        return points[Math.floor(Math.random() * points.length)];
    }
}